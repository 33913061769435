"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewEcommerceTrackingData = exports.UniversityCardClickEcommerceTrackingData = exports.ClickEcommerceTrackingData = exports.PartnerIncentiveClickEcommerceTrackingData = exports.PartnerIncentiveImpressionData = exports.UniversityCardImpressionData = exports.ScholarshipCardImpressionData = exports.StudyCardImpressionData = exports.EcommerceTrackingData = exports.ProductType = exports.EventName = exports.ListName = exports.ListEntityType = exports.ListPageType = exports.Product = exports.Action = exports.Tracker = void 0;
const tracker_1 = require("./src/domain/tracking/tracker");
Object.defineProperty(exports, "Tracker", { enumerable: true, get: function () { return tracker_1.Tracker; } });
const action_1 = require("./src/enums/action");
Object.defineProperty(exports, "Action", { enumerable: true, get: function () { return action_1.Action; } });
const product_1 = require("./src/enums/product");
Object.defineProperty(exports, "Product", { enumerable: true, get: function () { return product_1.Product; } });
const product_type_1 = require("./src/enums/product-type");
Object.defineProperty(exports, "ProductType", { enumerable: true, get: function () { return product_type_1.ProductType; } });
const ecommerce_tracking_data_class_1 = require("./src/interfaces/ecommerce-tracking-data.class");
Object.defineProperty(exports, "EcommerceTrackingData", { enumerable: true, get: function () { return ecommerce_tracking_data_class_1.EcommerceTrackingData; } });
const study_card_impression_class_1 = require("./src/interfaces/study-card-impression.class");
Object.defineProperty(exports, "StudyCardImpressionData", { enumerable: true, get: function () { return study_card_impression_class_1.StudyCardImpressionData; } });
const click_ecommerce_tracking_data_class_1 = require("./src/interfaces/click-ecommerce-tracking-data.class");
Object.defineProperty(exports, "ClickEcommerceTrackingData", { enumerable: true, get: function () { return click_ecommerce_tracking_data_class_1.ClickEcommerceTrackingData; } });
const view_ecommerce_tracking_data_class_1 = require("./src/interfaces/view-ecommerce-tracking-data.class");
Object.defineProperty(exports, "ViewEcommerceTrackingData", { enumerable: true, get: function () { return view_ecommerce_tracking_data_class_1.ViewEcommerceTrackingData; } });
const list_page_type_1 = require("./src/enums/list-page-type");
Object.defineProperty(exports, "ListPageType", { enumerable: true, get: function () { return list_page_type_1.ListPageType; } });
const list_entity_type_1 = require("./src/enums/list-entity-type");
Object.defineProperty(exports, "ListEntityType", { enumerable: true, get: function () { return list_entity_type_1.ListEntityType; } });
const list_name_1 = require("./src/enums/list-name");
Object.defineProperty(exports, "ListName", { enumerable: true, get: function () { return list_name_1.ListName; } });
const event_name_1 = require("./src/enums/event-name");
Object.defineProperty(exports, "EventName", { enumerable: true, get: function () { return event_name_1.EventName; } });
const scholarship_card_impression_class_1 = require("./src/interfaces/scholarship-card-impression.class");
Object.defineProperty(exports, "ScholarshipCardImpressionData", { enumerable: true, get: function () { return scholarship_card_impression_class_1.ScholarshipCardImpressionData; } });
const partner_incentive_impression_data_class_1 = require("./src/interfaces/partner-incentive-impression-data.class");
Object.defineProperty(exports, "PartnerIncentiveImpressionData", { enumerable: true, get: function () { return partner_incentive_impression_data_class_1.PartnerIncentiveImpressionData; } });
const partner_incentive_click_data_class_1 = require("./src/interfaces/partner-incentive-click-data.class");
Object.defineProperty(exports, "PartnerIncentiveClickEcommerceTrackingData", { enumerable: true, get: function () { return partner_incentive_click_data_class_1.PartnerIncentiveClickEcommerceTrackingData; } });
const university_card_impression_class_1 = require("./src/interfaces/university-card-impression.class");
Object.defineProperty(exports, "UniversityCardImpressionData", { enumerable: true, get: function () { return university_card_impression_class_1.UniversityCardImpressionData; } });
const university_card_click_ecommerce_tracking_data_class_1 = require("./src/interfaces/university-card-click-ecommerce-tracking-data.class");
Object.defineProperty(exports, "UniversityCardClickEcommerceTrackingData", { enumerable: true, get: function () { return university_card_click_ecommerce_tracking_data_class_1.UniversityCardClickEcommerceTrackingData; } });
