"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListName = void 0;
var ListName;
(function (ListName) {
    ListName["SEARCH_PAGE"] = "search_page";
    ListName["INLINE_SEARCH"] = "inline_search";
    ListName["SCHOLARSHIP_SEARCH_PAGE"] = "scholarship_search_page";
    ListName["UNIVERSITY_SEARCH_PAGE"] = "university_search_page";
    ListName["STUDY_CARD_STUDY_OPTIONS"] = "study_card_study_options";
    ListName["RECOMMENDER"] = "recommender";
    ListName["UNIVERSITY_HIGHLIGHTED"] = "university_highlighted";
    ListName["UNIVERSITY_PROGRAMMES"] = "university_programmes";
    ListName["STUDY_CARD_COUNTRY_DEGREE"] = "study_card_country_degree";
    ListName["STUDY_CARD_DISCIPLINE"] = "study_card_discipline";
    ListName["WISHLIST"] = "wishlist";
    ListName["META_RANKING"] = "meta_ranking";
    ListName["PARTNER_SECTION"] = "partner_section";
    ListName["ADMISSION_REQUIREMENTS_INSURANCE"] = "admission_requirements_insurance";
    ListName["ADMISSION_REQUIREMENTS_ENGLISH"] = "admission_requirements_english";
    ListName["BELOW_CONTENT_SWITCHER"] = "below_content_switcher";
    ListName["FINANCING_SECTION"] = "financing_section";
    ListName["OVERVIEW_FINANCING"] = "overview_financing";
    ListName["FEES_AND_FUNDING_FINANCING"] = "fees_and_funding_financing";
    ListName["CAREER_DATA_SECTION"] = "career_data_section";
})(ListName = exports.ListName || (exports.ListName = {}));
